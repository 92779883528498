import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {HttpClient} from "@angular/common/http";
import {ParameterNameCategoryGroupOrderSelector} from "../common";


@Injectable()
export class ParametersCategoryOrderService {

    private routePrefix: string = 'api/parameters';

    constructor(private http: HttpClient) {
    }

    getParamsGroupsForCategory(categoryId:number):Observable<Array<ParameterNameCategoryGroupOrderSelector>>{
        const url = `${this.routePrefix}/param-category-order/${categoryId}`;
        return this.http.get<Array<ParameterNameCategoryGroupOrderSelector>>(url);
    }

    getParamsGroupsForCategoryUpTheTree(categoryId:number):Observable<Array<ParameterNameCategoryGroupOrderSelector>>{
        const url = `${this.routePrefix}/param-category-up-the-tree/${categoryId}`;
        return this.http.get<Array<ParameterNameCategoryGroupOrderSelector>>(url);
    }


    moveGroupUp(catId:number,groupId:number): Observable<boolean> {
        const url = `${this.routePrefix}/param-category-order/move-group-up/${catId}/${groupId}`;
        return this.http.get<boolean>(url);
    }
    moveGroupDown(catId:number,groupId:number): Observable<boolean> {
        const url = `${this.routePrefix}/param-category-order/move-group-down/${catId}/${groupId}`;
        return this.http.get<boolean>(url);
    }


    moveParamUp(catId:number,groupId:number,paramId:number): Observable<boolean> {
        const url = `${this.routePrefix}/param-category-order/move-param-up/${catId}/${groupId}/${paramId}`;
        return this.http.get<boolean>(url);
    }
    moveParamDown(catId:number,groupId:number,paramId:number): Observable<boolean> {
        const url = `${this.routePrefix}/param-category-order/move-param-down/${catId}/${groupId}/${paramId}`;
        return this.http.get<boolean>(url);
    }
}
