import {
    Directive,
    ElementRef,
    Input, OnDestroy,
    OnInit,
    Renderer2
} from "@angular/core";
import {TabsDirective} from "./tabs.directive";
import {TabChangeEvent} from "./tab.common";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Directive({
    selector: '[fxTabBody]'
})

export class TabBodyDirective implements OnInit, OnDestroy {

    @Input('fxTabBody') fxTabBody: string = '0';
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor (private tabs: TabsDirective, private elRef: ElementRef, private ren: Renderer2) {
        this.tabs.selectedTabChanged
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: TabChangeEvent) => {
                if (e.selected === parseInt(this.fxTabBody)) {
                    this.ren.addClass(this.elRef.nativeElement, 'tabs__body-item--active');
                } else {
                    this.ren.removeClass(this.elRef.nativeElement, 'tabs__body-item--active');
                }
            });
    }

    ngOnInit() {
        if (this.tabs.selectedTab == parseInt(this.fxTabBody)) {
            this.ren.addClass(this.elRef.nativeElement, 'tabs__body-item--active');
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}