import {NgModule} from "@angular/core";
import {TabHeadDirective} from "./tab-head.directive";
import {TabBodyDirective} from "./tab-body.directive";
import {TabsDirective} from "./tabs.directive";

@NgModule({
    declarations: [
        TabHeadDirective,
        TabBodyDirective,
        TabsDirective
    ],
    exports: [
        TabHeadDirective,
        TabBodyDirective,
        TabsDirective
    ],
    providers: [
        TabsDirective
    ]
})

export class TabModule {}