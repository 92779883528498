import {Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {TabsDirective} from "./tabs.directive";
import {TabChangeEvent} from "./tab.common";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Directive({
    selector: '[fxTabHead]'
})

export class TabHeadDirective implements OnInit, OnDestroy {

    @Input('fxTabHead') fxTabHead: string = '0';
    @HostListener('click') onClick() {
        this.tabs.selectedTab = parseInt(this.fxTabHead);
    }

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor (private tabs: TabsDirective, private elRef: ElementRef, private ren: Renderer2) {
        this.tabs.selectedTabChanged
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: TabChangeEvent) => {
                if (e.selected === parseInt(this.fxTabHead)) {
                    this.ren.addClass(this.elRef.nativeElement, 'tabs__head-item--active');
                } else {
                    this.ren.removeClass(this.elRef.nativeElement, 'tabs__head-item--active');
                }
            });
    }

    ngOnInit() {
        if (this.tabs.selectedTab == parseInt(this.fxTabHead)) {
            this.ren.addClass(this.elRef.nativeElement, 'tabs__head-item--active');
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}