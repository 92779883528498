export interface ParameterNameLangSelector {
    id: number;
    cultureId: number;
    name: string;
    description: string;
    unit: string;

}

export interface ParameterNameSelector {

    id: number;
    parameterNameLangs: ParameterNameLangSelector[];
}


export interface ParameterGroupSelector {
    id: number;
    parameterGroupLangs: ParameterGroupLangSelector[];
}

export interface ParameterGroupLangSelector {
    id: number;
    cultureId: number;
    name: string;
}

export interface ParameterNamePagedResponse {
    params: Array<ParameterNameSelector>;
    total: number;
}


export interface ParameterNameCategorySelector {

    id: number;
    order: number;
    categoryId: number;
    categoryName: string;
    groupId: number;
    groupName: string;
    paramId: number;
    paramName: string;
    presentation: ParamCategoryPresentationSelector;
    showInFilter: boolean;
    isVariant: boolean;

}


export interface ParameterNameCategoryGroupOrderSelector {
    categoryId: number;
    categoryName: string;
    groupId: number;
    groupName: string;
    groupOrder: number;
    paramId: number;
    paramName: string;
    paramUnit: string;
    paramOrder: number;
    isVariant: boolean;
    isVariantSetPerProduct: boolean;
}

export interface ParamCategoryPresentationSelector {
    name: string;
    description: string;
}

export interface ParameterNameCategoryPagedResponse {
    params: Array<ParameterNameCategorySelector>;
    total: number;
}

export interface ParamCategoryGroupParamTreeNode {
    paramId: number;
    groupId: number;
    name: string;
    order: number;
    type: ItemType;
    children: Array<ParamCategoryGroupParamTreeNode>;
    isFirst: boolean;
    isLast: boolean;
    isEdited: boolean;
    isVariant: boolean;
    isVariantSetPerProduct:boolean;

    textInput: any;
    numericInput: any;
}

export enum ItemType {
    group = "group",
    param = "param"
}


export interface ParameterValueSelector {
    paramId: number;
    id: number;
    numericValue: number;
    isVariant: boolean;
    stringValueLangs: Array<ParamStringValueSelector>;
    paramName: string;
    unit: string;
}


export interface ParamStringValueSelector {
    id: number;
    paramValueId: number;
    cultureId: number;
    stringValue: string;
}
