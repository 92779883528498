import {Directive, EventEmitter, Injectable, Input, Output} from "@angular/core";
import {TabChangeEvent} from "./tab.common";

@Directive({
    selector: '[fxTabs]'
})

@Injectable()
export class TabsDirective {

    @Input('fxTabs') fxTabs: string;
    @Output('fxSelectedTabChanged') selectedTabChanged: EventEmitter<TabChangeEvent>;

    private _selectedTab: number = 0;
    get selectedTab(): number {
        return this._selectedTab;
    }

    set selectedTab(value: number) {
        this._selectedTab = value;
        this.selectedTabChanged.emit({tabGroupId: this.fxTabs, selected: value});
    }

    constructor () {
        this.selectedTabChanged = new EventEmitter<TabChangeEvent>();
    }
}